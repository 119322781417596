import React from "react";
import {
  Typography,
  TextField,
} from "@mui/material";
// MUI

const textFieldStyles = {
  width: "100%",
  backgroundColor: (theme) => theme.palette.common.white,
  borderRadius: (theme) => theme.spacing(1),

  input: {
    fontSize: "15px",
    height: (theme) => theme.spacing(2),
  },
  [`& fieldset`]: {
    borderRadius: (theme) => theme.spacing(1),
    border: "1px solid ",
    borderColor: (theme) => theme.palette.common.lightGray,
  },
};

const SendCodeForm = ({ handleChange }) => {

  return (
    <>
      <Typography variant="body1">
        Please enter you email and we'll send you a reset code
      </Typography>
      <TextField
        id="user-reset-password-email"
        variant="outlined"
        sx={{ ...textFieldStyles, mb: (theme) => theme.spacing(2) }}
        onChange={handleChange("_Email")}
        placeholder="Email"
        fullWidth
      />
      
    </>
  );
};

export default SendCodeForm;
