import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";

const DataTable = ({ rows, columns }) => {
  let rowsToShow = rows ? rows : []
 
  return (
    <Box flexGrow={1} style={{ height: "100%", width: "100%" }} >
      <DataGrid
        rows={rowsToShow}
        columns={columns}
        pageSize={30}
        rowsPerPageOptions={[30]}
        disableSelectionOnClick
        columnBuffer={5}
        disableColumnFilter={true}
      />
    </Box>
  );
};
export default DataTable;
