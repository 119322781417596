import { useState, useContext } from "react";

// Components
import LoginForm from "./Components/LoginForm";
import SendCodeForm from "./Components/SendCodeForm";
import NewPasswordForm from "./Components/NewPasswordForm";
import EnterCodeForm from "./Components/EnterCodeForm";
// Mui
import {
  Box,
  Typography,
  Button,
  useTheme,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Link,
} from "@mui/material";
// import useMediaQuery from "@mui/material/useMediaQuery";
import { useSnackbar } from "notistack";

// Variables
import {
  axiosSiteData,
  gvUserConnected,
  setGvUserConnected,
  langMessages,
} from "../../../variables";
import { UtilitiesContext, LoggedInContext } from "../../../contexts";

const submitButtonStyles = {
  height: (theme) => theme.spacing(5),
  borderRadius: (theme) => theme.spacing(1),
  backgroundColor: (theme) => theme.palette.primary.main,

  [`&:hover`]: {
    backgroundColor: (theme) => theme.palette.primary.dark,
  },
};

const forgotPwdStages = {
  sendCode: "enter-email",
  enterCode: "enter-code",
  enterNewPassword: "enter-new-password",
};

const Login = ({ show, handleClose, loading, setLoading, setFetchData }) => {
  // Context
  const { APIError } = useContext(UtilitiesContext);
  const { setLoggedIn } = useContext(LoggedInContext);

  // State
  const [formValues, setFormValues] = useState({
    _Email: "",
    _Password: "",
    newPassword: "",
    resetCode: "",
    showPassword: false,
  });

  const [resettingPassword, setResettingPassword] = useState(false);
  const [forgotPasswordStage, setForgotPasswordStage] = useState(
    forgotPwdStages.sendCode
  );
  const { enqueueSnackbar } = useSnackbar();

  // Form
  const handleChange = (prop) => (event) => {
    setFormValues({ ...formValues, [prop]: event.target.value });
  };

  const handleClickShowPassword = (e) => {
    e.preventDefault();
    setFormValues({ ...formValues, showPassword: !formValues.showPassword });
  };

  const handleSubmit = () => {
    // Formated form values
    const email = formValues._Email.trim();
    const password = formValues._Password.trim();
    const code = formValues.resetCode.trim();
    const newPassword = formValues.newPassword.trim();

    if (!resettingPassword) {
      // Submit login credentials
      // We make sure some fields aren't empty
      if (email === "" || password === "") {
        enqueueSnackbar("Please enter login credentials", { variant: "info" });
        return;
      }

      const loginCredentials = {
        _Email: email,
        _Password: password,
      };

      setLoading(true);
      axiosSiteData
        .post(`/login`, loginCredentials)
        .then((response) => {
          const theUser = response.data.User;
          setGvUserConnected({
            ...gvUserConnected,
            name: [theUser._FirstName, theUser._LastName].join(" "),
            email: theUser._Email,
            type: theUser._Type,
            token: response.data.Token,
          });
          // State
          setLoggedIn(true);
          handleClose();
          setFetchData(true)
        })
        .catch((errors) => {
          setLoading(false);
          APIError(errors);
        });
    } else if (
      resettingPassword &&
      forgotPasswordStage === forgotPwdStages.sendCode
    ) {
      // Send reset code to user (resetting password)
      // Alert user for empty field
      if (email === "") {
        enqueueSnackbar(
          langMessages.messages?.[gvUserConnected.lang]?.["Err.Login.Empty"] ||
            "Please enter your email",
          { variant: "info" }
        );
        return;
      }
      // Send code to user's email
      setLoading(true);
      // axiosSiteData
      //   .get(`/users/password/${email}/reset/send-code`)
      //   .then(() => {
      //     setLoading(false);
      //     enqueueSnackbar("Check your email inbox", {
      //       variant: "success",
      //     });
      //     setForgotPasswordStage(forgotPwdStages.enterCode);
      //   })
      //   .catch((errors) => {
      //     setLoading(false);
      //     APIError(errors);
      //   });

      // TODO: Remove when API enpoints are ready
      setForgotPasswordStage(forgotPwdStages.enterCode);
      enqueueSnackbar("Check your email inbox", {
        variant: "success",
      });
      setLoading(false);
      //
    } else if (
      resettingPassword &&
      forgotPasswordStage === forgotPwdStages.enterCode
    ) {
      // Enter code (resetting password)
      // Alert user for empty field
      if (code === "") {
        enqueueSnackbar(
          langMessages.messages?.[gvUserConnected.lang]?.["Err.Login.Empty"] ||
            "Please enter the password reset code",
          { variant: "error" }
        );
        return;
      }
      // Submit reset code
      setLoading(true);

      // axiosSiteData
      //   .get(`/users/password/${email}/reset/${code}`)
      //   .then(() => {
      //     setLoading(false);
      //     setForgotPasswordStage(forgotPwdStages.enterNewPassword);
      //   })
      //   .catch((errors) => {
      //     setLoading(false);
      //     APIError(errors);
      //   });
      // TODO: Remove when API enpoints are ready
      setForgotPasswordStage(forgotPwdStages.enterNewPassword);
      setLoading(false);
    } else if (
      resettingPassword &&
      forgotPasswordStage === forgotPwdStages.enterNewPassword
    ) {
      // Alert user for empty field
      if (newPassword === "") {
        enqueueSnackbar(
          langMessages.messages?.[gvUserConnected.lang]?.["Err.Login.Empty"] ||
            "Please enter your new password",
          { variant: "error" }
        );
        return;
      }
      // Submit reset code
      setLoading(true);

      //   axiosSiteData
      //     .get(`/users/password/${email}/reset/${code}/${newPassword}`)
      //     .then(() => {
      //       setLoading(false);
      //       enqueueSnackbar(
      //         langMessages.messages?.[gvUserConnected.lang]?.[
      //           "Err.Login.Empty"
      //         ] || "Password successfuly changed",
      //         { variant: "success" }
      //       );
      //       // Reset password resetting process
      //       setForgotPasswordStage(forgotPwdStages.sendCode);
      //       // Reset fields
      //       setFormValues({
      //         ...formValues,
      //         resetCode: "",
      //         newPassword: "",
      //         showPassword: false,
      //       });
      //       // Go back to login form
      //       setResettingPassword(false);
      //     })
      //     .catch((errors) => {
      //       setLoading(false);
      //       APIError(errors);
      //     });
      setLoading(false);
      enqueueSnackbar("Password successfuly changed", { variant: "success" });
      // Reset password resetting process
      setForgotPasswordStage(forgotPwdStages.sendCode);
      // Reset fields
      setFormValues({
        ...formValues,
        resetCode: "",
        newPassword: "",
        showPassword: false,
      });
      setResettingPassword(false);
      handleClose();
    }
  };

  // Mui
  const theme = useTheme();
  // const smallerScreen = useMediaQuery("(max-height: 840px)");

  let submitButtonLabel = "Sign in";
  if (forgotPasswordStage === forgotPwdStages.sendCode) {
    submitButtonLabel = "Send";
  }
  if (forgotPasswordStage === forgotPwdStages.enterCode) {
    submitButtonLabel = "Submit";
  }
  if (forgotPasswordStage === forgotPwdStages.enterNewPassword) {
    submitButtonLabel = "Submit";
  }

  return (
    <Dialog open={show} onClose={handleClose}>
      <DialogTitle
        sx={{
          fontSize: "2.6rem",
          mx: "auto",
          color: (theme) => theme.palette.primary.main,
        }}
      >
        {resettingPassword ? "Reset Password": "Login"}
      </DialogTitle>
      <DialogContent
        sx={{
          width: "420px",
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            height: "240px",
            display: "flex",
            flexDirection: "column",
            justifyContent: " space-between",
            pb: (theme) => theme.spacing(1.8),
            pt: (theme) => theme.spacing(0.1),
            px: (theme) => theme.spacing(2),
          }}
        >
          {/* Login Form */}
          {!resettingPassword && (
            <LoginForm
              formValues={formValues}
              handleChange={handleChange}
              handleClickShowPassword={handleClickShowPassword}
            />
          )}

          {/* Reset password forms */}
          {resettingPassword &&
            forgotPasswordStage === forgotPwdStages.sendCode && (
              <SendCodeForm handleChange={handleChange} loading={loading} />
            )}

          {resettingPassword &&
            forgotPasswordStage === forgotPwdStages.enterCode && (
              <EnterCodeForm handleChange={handleChange} loading={loading} />
            )}

          {resettingPassword &&
            forgotPasswordStage === forgotPwdStages.enterNewPassword && (
              <NewPasswordForm
                handleChange={handleChange}
                loading={loading}
                showPassword={formValues.showPassword}
                handleClickShowPassword={handleClickShowPassword}
              />
            )}

          {/* Forgot password button / Go back to login */}
          <Link underline="none" sx={{ cursor: "pointer" }}>
            <Typography
              variant="body2"
              color={theme.palette.common.link}
              sx={{ mt: (theme) => theme.spacing(1) }}
              onClick={
                resettingPassword
                  ? () => {
                      // Go back to sending code to user (forgot password)
                      setForgotPasswordStage(forgotPwdStages.sendCode);
                      // Reset fields
                      setFormValues({
                        ...formValues,
                        _Email: "",
                        resetCode: "",
                        newPassword: "",
                        showPassword: false,
                      });
                      // Go back to login form
                      setResettingPassword(false);
                    }
                  : () => setResettingPassword(true) // Start resetting of password process
              }
            >
              {resettingPassword ? "Return to login" : "Forgot password?"}
            </Typography>
          </Link>
          <Box sx={{ mt: (theme) => theme.spacing(1) }}>
            {/* Submit */}
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              sx={submitButtonStyles}
              onClick={() => (!loading ? handleSubmit() : {})}
            >
              {!loading ? (
                <Typography
                  sx={{
                    color: (theme) => theme.palette.common.white,
                    fontSize: "1.1rem",
                  }}
                >
                  {submitButtonLabel}
                </Typography>
              ) : (
                <CircularProgress />
              )}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default Login;
