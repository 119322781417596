import React from "react";
import {
  useTheme,
  useMediaQuery,
  Dialog,
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
} from "@mui/material";

const containerStyles = {
  padding: (theme) => `${theme.spacing(2)} ${theme.spacing(3)}`,
  boxSizing: "border-box",

  borderRadius: (theme) => theme.spacing(1),
  zIndex: "1",
  color: "white",
};

function Dialoger({ title, content, onClose, onAction, loading }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOk = () => {
    onAction(setOpen);
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      aria-labelledby="responsive-dialoger"
      onClose={handleClose}
    >
      <Box sx={containerStyles}>
        <DialogTitle
          id="responsive-dialoger"
          sx={{
            fontSize: "2rem",
            color: (theme) => theme.palette.primary.main,
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent >
          <Typography>{content}</Typography>
        </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              No
            </Button>
            <Button onClick={handleOk} color="error">
              Yes
            </Button>
          </DialogActions>
       
      </Box>
    </Dialog>
  );
}

export default Dialoger;
