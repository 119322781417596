import React from "react";

// Mui
import {
  Box,
  InputAdornment,
  IconButton,
  TextField,
  useTheme,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const textFieldStyles = {
  width: "100%",
  backgroundColor: (theme) => theme.palette.common.white,
  borderRadius: (theme) => theme.spacing(1),

  input: {
    fontSize: "15px",
    height: (theme) => theme.spacing(2),
  },
  [`& fieldset`]: {
    borderRadius: (theme) => theme.spacing(1),
    border: "1px solid ",
    borderColor: (theme) => theme.palette.common.lightGray,
  },
};

const LoginForm = ({ formValues, handleChange, handleClickShowPassword }) => {
  const theme = useTheme();
  return (
    <Box>
      {/* Email */}
      <TextField
        id="user-email"
        variant="outlined"
        type="text"
        sx={{ ...textFieldStyles, mb: (theme) => theme.spacing(2) }}
        onChange={handleChange("_Email")}
        placeholder="Email"
        fullWidth
      />

      {/* Password */}
      <TextField
        id="user-password"
        variant="outlined"
        type={formValues && formValues.showPassword ? "text" : "password"}
        sx={textFieldStyles}
        onChange={handleChange("_Password")}
        placeholder="Password"
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="start"
                style={{ color: theme.palette.primary.main }}
              >
                {formValues && formValues.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default LoginForm;
