import { useState } from "react";

// Components

// Mui
import {
  Box,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  TextField,
  useTheme,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Switch,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useSnackbar } from "notistack";

const selectFieldStyles = {
  marginBottom: 2,
  borderRadius: (theme) => theme.spacing(1),
  backgroundColor: (theme) => theme.palette.common.white,

  [`& fieldset`]: {
    border: "1px solid ",
    borderColor: (theme) => theme.palette.common.lightGray,
  },
};

const selectFieldStylesMuted = {
  borderRadius: (theme) => theme.spacing(1),
  color: (theme) => theme.palette.text.muted,
  marginBottom: 2,
  fontSize: "15px",
  letterSpacing: "0.5px",

  [`& fieldset`]: {
    border: "1px solid ",
    borderColor: (theme) => theme.palette.common.lightGray,
  },
};

const textFieldStyles = {
  color: (theme) => theme.palette.text.muted,
  marginBottom: 2,
  width: "100%",
  backgroundColor: (theme) => theme.palette.common.white,
  borderRadius: (theme) => theme.spacing(1),

  input: {
    fontSize: "15px",
    height: (theme) => theme.spacing(2),
  },
  [`& fieldset`]: {
    borderRadius: (theme) => theme.spacing(1),
    border: "1px solid ",
    borderColor: (theme) => theme.palette.common.lightGray,
  },
};

const submitButtonStyles = {
  height: (theme) => theme.spacing(5),
  borderRadius: (theme) => theme.spacing(1),
  backgroundColor: (theme) => theme.palette.primary.main,
  mt: (theme) => theme.spacing(1),
  [`&:hover`]: {
    backgroundColor: (theme) => theme.palette.primary.dark,
  },
};

const selectCountyNoneObject = { id: "none", _Name: "none" };

const ExtensionForm = ({
  show,
  handleClose,
  action,
  extensionData,
  onAddExtension,
  onEditExtension,
  counties,
  series,
}) => {
  // Helpers
  const getCountyCodeFromName = (name) => {
    return counties.find((county) => county._Name === name);
  };
  
  const extensionDataTemplate = {
    _CountyCode: "",
    _County: selectCountyNoneObject,
    _Member: false,
    _Series: "none",
    _IntercomNum: "",
    _Name: "",
    _MacAddress: "",
    _SerialNum: "",
    _NoGeoNum: "",
    _Model: "",
    _GeoPasswrd: "",
    _StateCode: "",
    showPassword: false,
  };
  // State
  const [formValues, setFormValues] = useState(
    extensionData
      ? {
          ...extensionData,
          _County: getCountyCodeFromName(extensionData._County),
          showPassword: false,
        }
      : extensionDataTemplate
  );
  const [loading] = useState(false);

  console.log("formValues", formValues);

  const { enqueueSnackbar } = useSnackbar();

  // Form
  const handleChange = (prop) => (event) => {
    if (prop === "_County")
      setFormValues({
        ...formValues,
        [prop]: event.target.value,
        _CountyCode: event.target.value._Code,
      });
    else setFormValues({ ...formValues, [prop]: event.target.value });
  };

  const handleClickShowPassword = (e) => {
    e.preventDefault();
    setFormValues({ ...formValues, showPassword: !formValues.showPassword });
  };

  // Submit data
  const handleSubmit = () => {
    // Formated form values
    const countyCode = formValues._CountyCode.trim();
    const county = formValues._County._Name.trim();
    const member = formValues._Member;
    const series = formValues._Series.trim();
    const intercomNum = formValues._IntercomNum.trim();
    const name = formValues._Name.trim();
    const macAddress = formValues._MacAddress.trim();
    const serialNum = formValues._SerialNum.trim();
    const noGeoNum = formValues._NoGeoNum.trim();
    const model = formValues._Model.trim();
    const geoPassword = formValues._GeoPasswrd.trim();
    const stateCode = formValues._StateCode.trim();

    // Validation
    if (
      countyCode === "" ||
      county === "" ||
      county._Name === "none" ||
      series === "" ||
      intercomNum === "" ||
      name === "" ||
      macAddress === "" ||
      serialNum === "" ||
      noGeoNum === "" ||
      model === "" ||
      geoPassword === "" ||
      stateCode === ""
    ) {
      enqueueSnackbar("Please fill in all fields", { variant: "info" });
      return;
    }

    // Constuct data that will be sent to server
    const dataToSubmit = {
      ...formValues,
      _CountyCode: countyCode,
      _County: county,
      _Member: member,
      _Series: series,
      _IntercomNum: intercomNum,
      _Name: name,
      _MacAddress: macAddress,
      _SerialNum: serialNum,
      _NoGeoNum: noGeoNum,
      _Model: model,
      _GeoPasswrd: geoPassword,
      _StateCode: stateCode,
    };

    if (action === "add") {
      onAddExtension(dataToSubmit);
    } else {
      onEditExtension(dataToSubmit);
    }
  };

  // Mui
  const theme = useTheme();
  // const smallerScreen = useMediaQuery("(max-height: 840px)");
  return (
    <Dialog open={show} onClose={handleClose}>
      <DialogTitle
        sx={{
          fontSize: "2.6rem",
          mx: "auto",
          color: (theme) => theme.palette.primary.main,
        }}
      >
        {action === "add" ? "Add " : "Edit "}
        extension
      </DialogTitle>
      <DialogContent
        sx={{
          width: "420px",
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: " space-between",
            pb: (theme) => theme.spacing(1.8),
            pt: (theme) => theme.spacing(0.1),
            px: (theme) => theme.spacing(2),
          }}
        >
          <Box>
            {/* County  */}
            <FormControl fullWidth>
              <Select
                labelId="couty-select"
                id="couty-type-select"
                value={formValues._County}
                sx={
                  formValues._County._Name !== "none"
                    ? selectFieldStyles
                    : selectFieldStylesMuted
                }
                onChange={handleChange("_County")}
              >
                <MenuItem key="County" value={selectCountyNoneObject} disabled>
                  County
                </MenuItem>
                {counties &&
                  counties.map((option, idx) => (
                    <MenuItem key={idx} value={option}>
                      {option._Name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            {/* County Code  */}
            <TextField
              id="ext-country-code"
              variant="outlined"
              type="text"
              value={formValues._CountyCode}
              sx={textFieldStyles}
              placeholder="County Code"
              fullWidth
              disabled
            />

            {/* Series */}
            <FormControl fullWidth>
              <Select
                labelId="series-select"
                id="series-type-select"
                value={formValues._Series}
                sx={
                  formValues._Series !== "none"
                    ? selectFieldStyles
                    : selectFieldStylesMuted
                }
                onChange={handleChange("_Series")}
              >
                <MenuItem key="Series" value="none" disabled>
                  Series
                </MenuItem>
                {series &&
                  series.map((option, idx) => (
                    <MenuItem key={idx} value={option}>
                      {option}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            {/* IntercomNum / Extension */}
            <TextField
              id="ext-extension"
              variant="outlined"
              type="text"
              sx={textFieldStyles}
              value={formValues._IntercomNum}
              onChange={handleChange("_IntercomNum")}
              placeholder="Extension"
              fullWidth
            />

            {/* Name */}
            <TextField
              id="ext-name"
              variant="outlined"
              type="text"
              sx={textFieldStyles}
              value={formValues._Name}
              onChange={handleChange("_Name")}
              placeholder="Name"
              fullWidth
            />

            {/* MacAddress */}
            <TextField
              id="ext-mac-address"
              variant="outlined"
              type="text"
              sx={textFieldStyles}
              value={formValues._MacAddress}
              onChange={handleChange("_MacAddress")}
              placeholder="Mac Address"
              fullWidth
            />

            {/* Serial Number  */}
            <TextField
              id="ext-serial-number"
              variant="outlined"
              type="text"
              sx={textFieldStyles}
              value={formValues._SerialNum}
              onChange={handleChange("_SerialNum")}
              placeholder="Serial Number"
              fullWidth
            />

            {/* No Geo Number  */}
            <TextField
              id="ext-no-geo-number"
              variant="outlined"
              type="text"
              sx={textFieldStyles}
              value={formValues._NoGeoNum}
              onChange={handleChange("_NoGeoNum")}
              placeholder="No Geo Number"
              fullWidth
            />

            {/* Model  */}
            <TextField
              id="ext-model"
              variant="outlined"
              type="text"
              sx={textFieldStyles}
              value={formValues._Model}
              onChange={handleChange("_Model")}
              placeholder="Model"
              fullWidth
            />

            {/* Geo Password  */}
            <TextField
              id="ext-geo-pass"
              variant="outlined"
              type={formValues.showPassword ? "text" : "password"}
              sx={textFieldStyles}
              value={formValues._GeoPasswrd}
              onChange={handleChange("_GeoPasswrd")}
              placeholder="Geo Password"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="start"
                      style={{ color: theme.palette.primary.main }}
                    >
                      {formValues.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {/* State Code  */}
            <TextField
              id="ext-state-code"
              variant="outlined"
              type="text"
              sx={textFieldStyles}
              value={formValues._StateCode}
              onChange={handleChange("_StateCode")}
              placeholder="StateCode"
              fullWidth
            />

            {/* Member */}
            <FormControlLabel
              sx={{ mt: (theme) => theme.spacing(1) }}
              control={
                <Switch
                  label="Member"
                  checked={formValues._Member}
                  value={formValues._Member}
                  onChange={(e) =>
                    setFormValues({ ...formValues, _Member: e.target.checked })
                  }
                  color="primary"
                />
              }
              label="Member"
            />
          </Box>
          <Box sx={{ mt: (theme) => theme.spacing(1) }}>
            {/* Submit */}
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              sx={submitButtonStyles}
              onClick={() => (!loading ? handleSubmit() : {})}
            >
              {!loading ? (
                <Typography
                  sx={{
                    color: (theme) => theme.palette.common.white,
                    fontSize: "1.1rem",
                  }}
                >
                  Submit
                </Typography>
              ) : (
                <CircularProgress />
              )}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ExtensionForm;
