import React from "react";
import { Box } from "@mui/material";

const Background = ({ children }) => {
  return (
    <Box
      style={{
        background: "linear-gradient(to right, #64B5F6, #90CAF9)",
        boxSizing: "border-box",
        height: "100vh",
        width: "100vw",
      }}
    >
      {children}
    </Box>
  );
};

export default Background;
