export const colsExtensions = [
  { field: "_Name", headerName: "Name", width: 300 },
  { field: "_IntercomNum", headerName: "Extension", width: 150 },
  { field: "_County", headerName: "County ", width: 200 },
  { field: "_Series", headerName: "Series", width: 200 },
  { field: "_CountyCode", headerName: "Code", width: 150 },
];

export const colsExtensionsExpanded = [
  { field: "_CountyCode", headerName: "Code", flex: 1 },
  { field: "_County", headerName: "County ", flex: 1 },
  { field: "_Series", headerName: "Series", flex: 1 },
  { field: "_Member", headerName: "Member", flex: 1 },
  { field: "_IntercomNum", headerName: "Intercom Number ", flex: 1 },
  { field: "_Name", headerName: "Name", flex: 1 },
  { field: "_MacAddress", headerName: "MacAddress", flex: 1 },
  { field: "_SerialNum", headerName: "Serial Number", flex: 1 },
  { field: "_NoGeoNum", headerName: "NoGeo Number", flex: 1 },
  { field: "_Model", headerName: "Model", flex: 1 },
  { field: "_GeoPasswrd", headerName: "Geo Password", flex: 1 },
  { field: "_StateCode", headerName: "StateCode", flex: 1 },
];
export const colsUsers = [
  { field: "_FirstName", headerName: "First Name", flex: 1 },
  { field: "_LastName", headerName: "Last Name", flex: 1 },
  { field: "_Email", headerName: "Email", flex: 1 },
  { field: "_Type", headerName: "Type", flex: 1 },
  { field: "_Active", headerName: "Active", flex: 1 },
];

// Mock data
// export const mockRows = [
//   {
//     id: 4270850160,
//     _CountyCode: "34",
//     _County: "Jackson",
//     _Series: "State Prisons",
//     _Member: "member",
//     _IntercomNum: "number",
//     _Name: "Tucker",
//     _MacAddress: "2C:54:91:88:C9:E3",
//     _SerialNum: "9203781239",
//     _NoGeoNum: "1232323",
//     _Model: "321",
//     _GeoPasswrd: "password",
//     _StateCode: "ARK",
//   },
//   {
//     id: 3021182924,
//     _CountyCode: "32",
//     _County: "Baxter",
//     _Series: "Sheriff",
//     _Member: "member",
//     _IntercomNum: "number",
//     _Name: "Polk County Sheriff",
//     _MacAddress: "3C:54:12:88:A9:E5",
//     _SerialNum: "9203781239",
//     _NoGeoNum: "1232323",
//     _Model: "321",
//     _GeoPasswrd: "password",
//     _StateCode: "ARK",
//   },
//   {
//     id: 1150073492,
//     _CountyCode: "34",
//     _County: "Jackson",
//     _Series: "State Prisons",
//     _Member: "member",
//     _IntercomNum: "number",
//     _Name: "Tucker",
//     _MacAddress: "2C:54:91:88:C9:E3",
//     _SerialNum: "9203781239",
//     _NoGeoNum: "1232323",
//     _Model: "321",
//     _GeoPasswrd: "password",
//     _StateCode: "ARK",
//   },
//   {
//     id: 1688890451,
//     _CountyCode: "34",
//     _County: "Jackson",
//     _Series: "State Prisons",
//     _Member: "member",
//     _IntercomNum: "number",
//     _Name: "Tucker",
//     _MacAddress: "2C:54:91:88:C9:E3",
//     _SerialNum: "9203781239",
//     _NoGeoNum: "1232323",
//     _Model: "321",
//     _GeoPasswrd: "password",
//     _StateCode: "ARK",
//   },
//   {
//     id: 2065065230,
//     _CountyCode: "32",
//     _County: "Baxter",
//     _Series: "Sheriff",
//     _Member: "member",
//     _IntercomNum: "number",
//     _Name: "Polk County Sheriff",
//     _MacAddress: "3C:54:12:88:A9:E5",
//     _SerialNum: "9203781239",
//     _NoGeoNum: "1232323",
//     _Model: "321",
//     _GeoPasswrd: "password",
//     _StateCode: "ARK",
//   },
//   {
//     id: 3706049119,
//     _CountyCode: "34",
//     _County: "Jackson",
//     _Series: "State Prisons",
//     _Member: "member",
//     _IntercomNum: "number",
//     _Name: "Tucker",
//     _MacAddress: "2C:54:91:88:C9:E3",
//     _SerialNum: "9203781239",
//     _NoGeoNum: "1232323",
//     _Model: "321",
//     _GeoPasswrd: "password",
//     _StateCode: "ARK",
//   },
//   {
//     id: 3595673759,
//     _CountyCode: "34",
//     _County: "Jackson",
//     _Series: "State Prisons",
//     _Member: "member",
//     _IntercomNum: "number",
//     _Name: "Tucker",
//     _MacAddress: "2C:54:91:88:C9:E3",
//     _SerialNum: "9203781239",
//     _NoGeoNum: "1232323",
//     _Model: "321",
//     _GeoPasswrd: "password",
//     _StateCode: "ARK",
//   },
//   {
//     id: 2705203014,
//     _CountyCode: "32",
//     _County: "Baxter",
//     _Series: "Sheriff",
//     _Member: "member",
//     _IntercomNum: "number",
//     _Name: "Polk County Sheriff",
//     _MacAddress: "3C:54:12:88:A9:E5",
//     _SerialNum: "9203781239",
//     _NoGeoNum: "1232323",
//     _Model: "321",
//     _GeoPasswrd: "password",
//     _StateCode: "ARK",
//   },
//   {
//     id: 83644316,
//     _CountyCode: "34",
//     _County: "Jackson",
//     _Series: "State Prisons",
//     _Member: "member",
//     _IntercomNum: "number",
//     _Name: "Tucker",
//     _MacAddress: "2C:54:91:88:C9:E3",
//     _SerialNum: "9203781239",
//     _NoGeoNum: "1232323",
//     _Model: "321",
//     _GeoPasswrd: "password",
//     _StateCode: "ARK",
//   },
// ];



// export const mockRowsUsers = [
//   {
//     id: 1198367592,
//     _FirstName: "Chester",
//     _LastName: "French",
//     _Email: "johnjack@gmail.com",
//     _Active: true,
//   },
//   {
//     id: 3753629438,
//     _FirstName: "Clarence",
//     _LastName: "Greene",
//     _Email: "cgreene@gmail.com",
//     _Active: false,
//   },
//   {
//     id: 1815573536,
//     _FirstName: "Russell",
//     _LastName: "Johnston",
//     _Email: "rjohnston@gmail.com",
//     _Active: true,
//   },
// ];
