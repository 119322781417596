import React from "react";

import Login from "../src/Views/Pages/Auth/Login";
import Home from "../src/Views/Pages/Home/Home";
import { BrowserRouter as Router, Route, Routes  } from "react-router-dom";

function AppContent() {
  return (
    <Router >
      <Routes>
        {/* Login */}
        <Route exact path="/login" element={<Login />} />
        {/* Contacts */}
        <Route exact path="/" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default AppContent;
