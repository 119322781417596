import { useState, useEffect, useContext } from "react";

// PDF
import jsPDF from "jspdf";
import "jspdf-autotable";

import {
  gvUserConnected,
  axiosSiteData,
  axiosSiteDataConfig,
  setGvUserConnected,
  SeriesTypes,
  versionCode,
} from "../../../variables";
import { UtilitiesContext, LoggedInContext } from "../../../contexts";

// Components
import Background from "../../Components/Background";
import Logo from "../../Components/Logo";
import Sidebar from "./Components/Sidebar/Sidebar";
// import SearchBar from "./Components/SearchBar/SearchBar";
import DataTable from "./Components/DataTable/DataTable";
import LoginForm from "../Auth/Login";
import UserForm from "./Components/Users/UserForm";
import ExtensionForm from "./Components/Extensions/ExtensionForm";
import Dialoger from "../../Components/Dialoger";

// Mui
import {
  Box,
  Typography,
  Button,
  IconButton,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { useSnackbar } from "notistack";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AccountCircle from "@mui/icons-material/AccountCircle";
import useMediaQuery from "@mui/material/useMediaQuery";
import DownloadIcon from "@mui/icons-material/Download";
// Table data
import {
  colsExtensions,
  colsExtensionsExpanded,
  colsUsers,
} from "../../../res/data/tableData";

const containerStyles = {
  display: "flex",
  boxSizing: "border-box",
  width: "100%",
  height: "100%",
  backgroundColor: "white",
  borderRadius: "20px",
  zIndex: "1",
  boxShadow: "0px 5px 25px rgba(0, 0, 0, 0.15)",
};

const contentStyles = {
  display: "flex",
  flexDirection: "column",
  height: "94vh",
  margin: "0 40px",
  padding: "10px 0 ",
};

const loginButtonStyles = {
  backgroundColor: (theme) => theme.palette.primary.main,
  p: "0 25px",
  fontSize: "1.1rem",
  height: "100%",
};
const logoutButtonStyles = {
  backgroundColor: (theme) => theme.palette.error.main,
  p: "0 25px",
  fontSize: "1.1rem",
  height: "100%",
  [`&:hover`]: {
    backgroundColor: (theme) => theme.palette.error.dark,
  },
};

let seriesList = [];
for (let series in SeriesTypes) {
  seriesList.push(SeriesTypes[series]);
}

const Home = () => {
  // State
  const [series] = useState(seriesList);
  const [counties, setCounties] = useState();

  const [lookup, setLookup] = useState({
    series: "All",
    county: "All",
  });

  // Table columns state
  // Extensions
  const [columnsExt, setColumnsExt] = useState(colsExtensions);
  // Users
  const [columnsUsers] = useState(colsUsers);

  const [fetchData, setFetchData] = useState(false);

  // Conditional rendering state
  const [manageUsers, setManageUsers] = useState(false);
  const [loading, setLoading] = useState(false);

  // Users state
  const [userList, setUserList] = useState();
  const [userData, setUserData] = useState();
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showUserForm, setShowUserForm] = useState(false);
  const [userFormAction, setUserFormAction] = useState("add");

  // Extension state
  const [extensionList, setExtensionList] = useState();
  const [allExtensions, setAllExtensions] = useState();
  const [extensionData, setExtensionData] = useState();
  const [showExtensionForm, setShowExtensionForm] = useState(false);
  const [еxtensionFormAction, setExtensionFormAction] = useState("add");
  const [expandTable, setExpandTable] = useState(false);

  const [{ deleteCallback }, setDeleteCallback] = useState({
    deleteCallback: null,
  });

  // Context
  const { APIError, Logout } = useContext(UtilitiesContext);
  const { loggedIn } = useContext(LoggedInContext);

  // Mui
  const { enqueueSnackbar } = useSnackbar();

  // USER LOG IN REFRESH TOKEN
  // effects for checking token
  useEffect(() => {
    let theUser;
    try {
      theUser = JSON.parse(localStorage.getItem("user"));
      if (theUser.email.trim() === "") {
        //#TO-REVERTBACK
        Logout();
        // setPage('default')
        return;
      }
    } catch (_) {
      //#TO-REVERTBACK
      Logout();
      // setPage('default')
      return;
    }
    setGvUserConnected(theUser);

    const checkToken = () => {
      axiosSiteData
        .get(`/token/refresh`, axiosSiteDataConfig)
        .then((response) => {
          setGvUserConnected({
            ...theUser,
            token: response.data.result,
          });
          setLoading(false);
        })
        .catch((_) => {
          Logout();
        });
    };
    checkToken();

    let ticker = setInterval(() => {
      checkToken();
    }, 480000);

    // cancel timer
    return () => {
      clearInterval(ticker);
    };
  }, [Logout]);

  //────────────────────────────────────────────────────────────────────────────────────────────────────────
  //─────────── GET DATA ────────────────────────────────────────────────────────────────────────────────
  //────────────────────────────────────────────────────────────────────────────────────────────────────────
  // Get County codes
  const getCountyList = () => {
    axiosSiteData
      .get(`/countycode`)
      .then((res) => {
        setCounties(res.data);
        setLoading(false);
      })
      .catch((err) => {
        APIError(err);
        setLoading(false);
      });
    setFetchData(false);
  };

  // Get Extensions
  const getExtensionList = () => {
    let endpointAddition = "?";

    // Look up with county
    if (lookup.county !== "All") {
      endpointAddition += `county=${encodeURIComponent(lookup.county)}`;
      if (lookup.series !== "All") {
        endpointAddition += `&series=${encodeURIComponent(lookup.series)}`;
      }
    } else {
      if (lookup.series !== "All") {
        endpointAddition += `series=${encodeURIComponent(lookup.series)}`;
      }
    }

    axiosSiteData
      .get(`/accphoneext${endpointAddition}`)
      .then((res) => {
        setExtensionList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        APIError(err);
        setLoading(false);
      });
    setFetchData(false);
  };

  // Get full list of extensions
  // This is used for PDF export
  const getAllExtensions = () => {
    axiosSiteData
      .get(`/accphoneext`)
      .then((res) => {
        setAllExtensions(res.data);
        setLoading(false);
      })
      .catch((err) => {
        APIError(err);
        setLoading(false);
      });
    setFetchData(false);
  };

  // Get Users
  const getUserList = () => {
    if (manageUsers) {
      axiosSiteData
        .get(`/users`, axiosSiteDataConfig)
        .then((res) => {
          setUserList(res.data);
          setLoading(false);
        })
        .catch((err) => {
          APIError(err);
          setLoading(false);
        });
      setFetchData(false);
    }
  };

  // Get all data
  useEffect(getCountyList, [fetchData, APIError]);
  useEffect(getExtensionList, [fetchData, lookup, APIError, manageUsers]);
  useEffect(getAllExtensions, [fetchData, lookup, APIError, manageUsers]);
  useEffect(getUserList, [manageUsers, fetchData, APIError]);

  // Save delete callback to state
  const confirmTemplateDeletion = (deleteCallback) => {
    setDeleteCallback({ deleteCallback });
  };

  const withCustomRowStyles = (columns, field) => {
    const styles = {
      boolTextYes: {
        color: "#00A4FF",
      },
      boolTextNo: {
        color: "#ff3f34",
      },
    };
    return [
      ...columns.map((col) => {
        if (col.field !== field) {
          return col;
        } else {
          return {
            field: field,
            headerName: field.substring(1),
            flex: 1,
            renderCell: (params) => {
              return (
                <Typography
                  variant="body1"
                  style={
                    params.row[field] ? styles.boolTextYes : styles.boolTextNo
                  }
                >
                  {params.row[field] ? "Yes" : "No"}
                </Typography>
              );
            },
          };
        }
      }),
    ];
  };
  // Manage table data
  const withActionsColumn = (columns, target) => {
    return [
      ...columns,
      {
        field: "Actions",
        headerName: "Actions",
        flex: 2,
        renderCell: (params) => {
          const onEdit = (e) => {
            if (target === "extensions") {
              setExtensionData(params.row);
              // Show extension form
              setShowExtensionForm(true);

              // Handle form with edit action
              setExtensionFormAction("edit");
            } else {
              setUserData(params.row);
              // Show extension form
              setShowUserForm(true);

              // Handle form with edit action
              setUserFormAction("edit");
            }
          };

          const onDelete = (e) => {
            // Save the delete callback which will be sent to the server after confirmation
            confirmTemplateDeletion(() => {
              setLoading(true);
              let endpoint = target === "extensions" ? "accphoneext" : "users";
              // API Call
              axiosSiteData
                .delete(`/${endpoint}/${params.row._id}`, axiosSiteDataConfig)
                .then(() => {
                  // Fetch folders from server
                  enqueueSnackbar(
                    `Successfully deleted ${
                      target === "extensions" ? "extension" : "user"
                    }`,
                    {
                      variant: "success",
                    }
                  );
                  setFetchData(true);
                  setLoading(false);
                })
                .catch((err) => {
                  APIError(err);
                  setLoading(false);
                });
            });
          };

          return (
            <Box>
              <IconButton color="primary" onClick={(e) => onEdit(e)}>
                <EditIcon />
              </IconButton>
              <IconButton color="error" onClick={(e) => onDelete(e)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          );
        },
      },
    ];
  };

  const onChangeExpandedTable = (isExpanded) => {
    // Update state
    setExpandTable(isExpanded);

    // Update columns
    if (isExpanded) setColumnsExt(colsExtensionsExpanded);
    else setColumnsExt(colsExtensions);

    // Fetch new extensions data
    getExtensionList();
  };

  const onChangeManageUsers = (show) => {
    // Update state
    setManageUsers(show);
    // Fetch new users data
    getUserList();
  };

  //────────────────────────────────────────────────────────────────────────────────────────────────────────
  //─────────── USER ACTIONS ────────────────────────────────────────────────────────────────────────────────
  //────────────────────────────────────────────────────────────────────────────────────────────────────────

  const closeUserForm = () => {
    setShowUserForm(false);
    setUserFormAction("add");
    setUserData();
  };

  const onAddUser = (data) => {
    setLoading(true);
    // API Call
    axiosSiteData
      .post(`/users`, data, axiosSiteDataConfig)
      .then(() => {
        // Fetch folders from server
        enqueueSnackbar("Successfully added user", {
          variant: "success",
        });
        setFetchData(true);
        setLoading(false);
      })
      .catch((err) => {
        APIError(err);
        setLoading(false);
      });

    // Reset state and close form
    closeUserForm();
  };
  const onEditUser = (data) => {
    setLoading(true);
    // API Call
    axiosSiteData
      .put(`/users/${data._id}`, data, axiosSiteDataConfig)
      .then(() => {
        // Fetch folders from server
        enqueueSnackbar("Successfully edited user", {
          variant: "success",
        });
        setFetchData(true);
        setLoading(false);
      })
      .catch((err) => {
        APIError(err);
        setLoading(false);
      });
    // Reset state and close form
    closeUserForm();
  };

  const LogoutUser = () => {
    // Update page state
    setManageUsers(false);
    setExpandTable(false);

    // Log user out
    Logout();
  };

  //─────────────────────────────────────────────────────────────────────────────────────────────
  //─────────── EXTENSIONS ACTIONS ────────────────────────────────────────────────────────────────────────────────
  //─────────────────────────────────────────────────────────────────────────────────────────────

  const closeExtensionForm = () => {
    setShowExtensionForm(false);
    setExtensionFormAction("add");
    setExtensionData();
  };

  const onAddExtension = (data) => {
    setLoading(true);
    // API Call
    axiosSiteData
      .post(`/accphoneext`, data, axiosSiteDataConfig)
      .then(() => {
        // Fetch folders from server
        enqueueSnackbar("Successfully added extension", {
          variant: "success",
        });
        setFetchData(true);
        setLoading(false);
      })
      .catch((err) => {
        APIError(err);
        setLoading(false);
      });

    // Reset state and close form
    closeExtensionForm();
  };
  const onEditExtension = (data) => {
    setLoading(true);
    // API Call
    axiosSiteData
      .put(`/accphoneext/${data._id}`, data, axiosSiteDataConfig)
      .then(() => {
        // Fetch folders from server
        enqueueSnackbar("Successfully edited extension", {
          variant: "success",
        });
        setFetchData(true);
        setLoading(false);
      })
      .catch((err) => {
        APIError(err);
        setLoading(false);
      });
    // Reset state and close form
    closeExtensionForm();
  };
  console.log('allExtensions', allExtensions)

  // Download extensions
  // PDF format
  const downloadExtensions = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Justice Bridge Extensions";
    const headers = [["Name", "Extension", "County", "Series", "Code"]];

    const data = allExtensions.map((ext) => [
      ext._Name,
      ext._IntercomNum,
      ext._County,
      ext._Series,
      ext._CountyCode,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Justice_Bridge_Extensions.pdf");
  };

  // Message displayed in delete dialog
  let dialogerTarget = "";
  if (manageUsers) {
    dialogerTarget = "user";
  } else {
    dialogerTarget = "extension";
  }

  // id value is required for DataGrid component
  const withIDField = (rows) => {
    return rows && [...rows.map((row) => ({ ...row, id: row._id }))];
  };

  // Render table data accordingly
  let rowsToShow = manageUsers
    ? withIDField(userList)
    : withIDField(extensionList);

  let colsToShow = manageUsers
    ? withIDField(
        withActionsColumn(withCustomRowStyles(columnsUsers, "_Active"), "users")
      )
    : expandTable
    ? withIDField(
        withActionsColumn(
          withCustomRowStyles(columnsExt, "_Member"),
          "extensions"
        )
      )
    : withIDField(columnsExt);

  const smallerScreen = useMediaQuery("(max-height: 840px)");

  return (
    <Background>
      <Box sx={contentStyles}>
        {/* Keystone Logo */}
        <Box flexGrow={0} sx={{ mb: (theme) => theme.spacing(1) }}>
          <Logo width={smallerScreen ? "180px" : "235px"} />
        </Box>
        <Box display="flex" justifyContent="center" flexGrow={1}>
          <Box sx={containerStyles}>
            {/* Sidebar */}
            <Sidebar
              counties={counties}
              series={series}
              lookup={lookup}
              onLookupChange={setLookup}
              manageUsers={manageUsers}
              onChangeManageUsers={(show) => onChangeManageUsers(show)}
            />
            {/* Main panel */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                padding: (theme) => theme.spacing(4),
                borderRadius: "10px",
              }}
            >
              {/* Header */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexGrow: 0,
                  mb: (theme) => theme.spacing(4.2),
                  height: "50px",
                }}
              >
                <Box>
                  <Box display="flex " height={1}>
                    {/* Table label */}
                    <Typography
                      sx={{
                        mr: (theme) => theme.spacing(2),
                        fontSize: smallerScreen ? "1.8rem" : "2rem",
                      }}
                    >
                      {manageUsers ? "Users" : "Extension Directory"}
                    </Typography>
                    {/* Add user/extension button */}
                    {loggedIn && (
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.primary.main,
                          p: "0 25px",
                          fontSize: "1.1rem",
                          height: "100%",
                        }}
                        onClick={
                          manageUsers
                            ? () => {
                                // Show user form
                                setShowUserForm(true);

                                // Handle form with edit action
                                setUserFormAction("add");
                              }
                            : () => {
                                // Show extension form
                                setShowExtensionForm(true);

                                // Handle form with edit action
                                setExtensionFormAction("add");
                              }
                        }
                      >
                        Add
                      </Button>
                    )}
                  </Box>

                  {/* Download button */}
                  <Box display="flex" alignItems="center" sx={{ my: "8px" }}>
                    <Typography sx={{ mr: "5px", wordBreak: "break-word" }}>
                      Download full extensions list
                    </Typography>
                    <IconButton variant="contained" sx={{ mr: "10px" }}>
                      <DownloadIcon onClick={() => downloadExtensions()} />
                    </IconButton>

                    {loggedIn && (
                      // Expand extension table
                      <FormControlLabel
                        control={
                          <Switch
                            checked={expandTable}
                            onChange={(e) =>
                              onChangeExpandedTable(e.target.checked)
                            }
                            color="primary"
                            disabled={manageUsers}
                          />
                        }
                        label="Expand"
                      />
                    )}
                  </Box>
                </Box>

                {/* Logged in user message  */}
                <Box height={1} sx={{ display: "flex" }}>
                  {loggedIn && (
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{ mr: (theme) => theme.spacing(2), width: "200px" }}
                    >
                      <AccountCircle
                        color="primary"
                        sx={{
                          fontSize: "2.5rem",
                          mr: (theme) =>
                            smallerScreen
                              ? theme.spacing(1.2)
                              : theme.spacing(2),
                        }}
                      />
                      <Typography
                        sx={{ fontSize: "1.2rem", wordBreak: "break-word" }}
                      >
                        {gvUserConnected.name}
                      </Typography>
                    </Box>
                  )}

                  {/* Login / Logout button */}
                  <Button
                    variant="contained"
                    sx={loggedIn ? logoutButtonStyles : loginButtonStyles}
                    onClick={() =>
                      loggedIn ? LogoutUser() : setShowLoginForm(!showLoginForm)
                    }
                  >
                    {loggedIn ? "Logout" : "Login"}
                  </Button>
                </Box>
              </Box>

              {/* Table of contents */}
              <Box
                display="flex"
                flexDirection="column"
                flexGrow={1}
                sx={{ mt: "10px" }}
              >
                {/* Table */}
                <DataTable rows={rowsToShow} columns={colsToShow} />
              </Box>

              {/* Dialogs */}

              {/* Login form */}
              <LoginForm
                show={showLoginForm}
                handleClose={() => setShowLoginForm(false)}
                loading={loading}
                setLoading={setLoading}
                setFetchData={setFetchData}
              />

              {/* User add/edit form */}
              {showUserForm && (
                <UserForm
                  userData={userData}
                  action={userFormAction}
                  show={showUserForm}
                  handleClose={() => {
                    closeUserForm();
                  }}
                  onAddUser={onAddUser}
                  onEditUser={onEditUser}
                  loading={loading}
                />
              )}

              {/* Extension add/edit form */}
              {showExtensionForm && (
                <ExtensionForm
                  extensionData={extensionData}
                  action={еxtensionFormAction}
                  show={showExtensionForm}
                  handleClose={() => closeExtensionForm()}
                  onAddExtension={onAddExtension}
                  onEditExtension={onEditExtension}
                  loading={loading}
                  counties={counties}
                  series={series}
                />
              )}

              {/* DELETE dialoger */}
              {deleteCallback && (
                <Dialoger
                  title={`Delete ${dialogerTarget}`}
                  content={`Are you sure you want to delete this ${dialogerTarget}?`}
                  onClose={() => {
                    setDeleteCallback({ deleteCallback: null });
                  }}
                  onAction={() => {
                    deleteCallback();
                    setDeleteCallback({ deleteCallback: null });
                  }}
                  loading={loading}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Typography sx={{ float: "right", mr: "10px", color: "#5ea4e0" }}>
        v{versionCode}
      </Typography>
    </Background>
  );
};

export default Home;
