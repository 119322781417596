import { useState } from "react";

// Components

// Mui
import {
  Box,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  TextField,
  useTheme,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Switch,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import useMediaQuery from "@mui/material/useMediaQuery";
import { useSnackbar } from "notistack";

// Variables
import {
  UserTypes,
} from "../../../../../variables";


const selectFieldStyles = {
  marginBottom: 2,
  borderRadius: (theme) => theme.spacing(1),
  backgroundColor: (theme) => theme.palette.common.white,

  [`& fieldset`]: {
    border: "1px solid ",
    borderColor: (theme) => theme.palette.common.lightGray,
  },
};

const selectFieldStylesMuted = {
  borderRadius: (theme) => theme.spacing(1),
  color: (theme) => theme.palette.text.muted,
  marginBottom: 2,
  fontSize: "15px",
  letterSpacing: "0.5px",

  [`& fieldset`]: {
    border: "1px solid ",
    borderColor: (theme) => theme.palette.common.lightGray,
  },
};

const textFieldStyles = {
  width: "100%",
  backgroundColor: (theme) => theme.palette.common.white,
  borderRadius: (theme) => theme.spacing(1),

  input: {
    fontSize: "15px",
    height: (theme) => theme.spacing(2),
  },
  [`& fieldset`]: {
    borderRadius: (theme) => theme.spacing(1),
    border: "1px solid ",
    borderColor: (theme) => theme.palette.common.lightGray,
  },
};

const submitButtonStyles = {
  height: (theme) => theme.spacing(5),
  borderRadius: (theme) => theme.spacing(1),
  backgroundColor: (theme) => theme.palette.primary.main,
  mt: (theme) => theme.spacing(1),
  [`&:hover`]: {
    backgroundColor: (theme) => theme.palette.primary.dark,
  },
};

const UserForm = ({
  show,
  handleClose,
  action,
  userData,
  onAddUser,
  onEditUser,
  loading
}) => {
  const userDataTemplate = {
    _FirstName: "",
    _LastName: "",
    _Email: "",
    _Type: "none",
    _Password: "",
    _Active: false,
    showPassword: false,
  };

  // Context

  // State
  const [formValues, setFormValues] = useState(
    userData ? { ...userData, showPassword: false } : userDataTemplate
  );

  const { enqueueSnackbar } = useSnackbar();

  // Form
  const handleChange = (prop) => (event) => {
    setFormValues({ ...formValues, [prop]: event.target.value });
  };

  const handleClickShowPassword = (e) => {
    e.preventDefault();
    setFormValues({ ...formValues, showPassword: !formValues.showPassword });
  };

  // Submit data
  const handleSubmit = () => {
    // Formated form values
    const firstName = formValues._FirstName && formValues._FirstName.trim();
    const lastName = formValues._LastName && formValues._LastName.trim();
    const email = formValues._Email && formValues._Email.trim();
    const password = formValues._Password && formValues._Password.trim();

    // We make sure some fields aren't empty
    if (
      firstName === "" ||
      lastName === "" ||
      email === ""
    ) {
      enqueueSnackbar("Please fill in all fields", { variant: "info" });
      return;
    }

    // Constuct data that will be sent to server
    const dataToSubmit = {
      ...formValues,
      _FirstName: firstName,
      _LastName: lastName,
      _Email: email,
      _Password: password,
    };

    if (action === "add") {
      onAddUser(dataToSubmit);
    } else {
      onEditUser(dataToSubmit);
    }
  };
  // Mui
  const theme = useTheme();
  // const smallerScreen = useMediaQuery("(max-height: 840px)");

  return (
    <Dialog open={show} onClose={handleClose}>
      <DialogTitle
        sx={{
          fontSize: "2.6rem",
          mx: "auto",
          color: (theme) => theme.palette.primary.main,
        }}
      >
        {action === "add" ? "Add " : "Edit "}
        user
      </DialogTitle>
      <DialogContent
        sx={{
          width: "420px",
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: " space-between",
            pb: (theme) => theme.spacing(1.8),
            pt: (theme) => theme.spacing(0.1),
            px: (theme) => theme.spacing(2),
          }}
        >
          <Box>
            {/* First name */}
            <TextField
              id="user-first-name"
              variant="outlined"
              type="text"
              sx={{ ...textFieldStyles, mb: (theme) => theme.spacing(2) }}
              value={formValues._FirstName}
              onChange={handleChange("_FirstName")}
              placeholder="First Name"
              fullWidth
            />

            {/* Last name */}
            <TextField
              id="user-last-name"
              variant="outlined"
              type="text"
              sx={{ ...textFieldStyles, mb: (theme) => theme.spacing(2) }}
              value={formValues._LastName}
              onChange={handleChange("_LastName")}
              placeholder="Last Name"
              fullWidth
            />
            {/* Email */}
            <TextField
              id="user-email"
              variant="outlined"
              type="text"
              sx={{ ...textFieldStyles, mb: (theme) => theme.spacing(2) }}
              value={formValues._Email}
              onChange={handleChange("_Email")}
              placeholder="Email"
              fullWidth
            />

            
            {/* Type  */}
            <FormControl fullWidth>
              <Select
                labelId="user-select"
                id="user-type-select"
                value={formValues._Type}
                sx={
                  formValues._Type !== "none"
                    ? selectFieldStyles
                    : selectFieldStylesMuted
                }
                onChange={handleChange("_Type")}
              >
               <MenuItem value="none" disabled>
                Type
              </MenuItem>
              <MenuItem value={UserTypes.SuperAdmin}>SuperAdmin</MenuItem>
              <MenuItem value={UserTypes.Admin}>Admin</MenuItem>
              <MenuItem value={UserTypes.User}>User</MenuItem>
              </Select>
            </FormControl>

            {/* Password */}
            <TextField
              id="user-password"
              variant="outlined"
              type={formValues.showPassword ? "text" : "password"}
              sx={textFieldStyles}
              value={formValues._Password}
              onChange={handleChange("_Password")}
              placeholder="Password"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="start"
                      style={{ color: theme.palette.primary.main }}
                    >
                      {formValues.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* Active */}
            <FormControlLabel
              sx={{ mt: (theme) => theme.spacing(1) }}
              control={
                <Switch
                  label="Active"
                  checked={formValues._Active}
                  onChange={(e) =>
                    setFormValues({ ...formValues, _Active: e.target.checked })
                  }
                  color="primary"
                />
              }
              label="Active"
            />
          </Box>
          <Box sx={{ mt: (theme) => theme.spacing(1) }}>
            {/* Submit */}
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              sx={submitButtonStyles}
              onClick={() => (!loading ? handleSubmit() : {})}
            >
              {!loading ? (
                <Typography
                  sx={{
                    color: (theme) => theme.palette.common.white,
                    fontSize: "1.1rem",
                  }}
                >
                  Submit
                </Typography>
              ) : (
                <CircularProgress />
              )}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default UserForm;
