import React, { useContext } from "react";
import LookupSelectForms from "./LookupSelectForms";
import justiceBridgeLogo from "../../../../../res/images/justice-bridge-logo.png";
import aacLogo from "../../../../../res/images/aaclog.png";

// Context
import { LoggedInContext } from "../../../../../contexts";

// Mui
import { Box, useTheme, Typography, Switch, Divider } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

const menuBoxStyles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  height: "100%",
  width: "340px",
  boxSizing: "border-box",
  backgroundColor: (theme) => theme.palette.secondary.main,
  borderRadius: "20px ",
  opacity: 1,
  padding: (theme) => `${theme.spacing(2)} ${theme.spacing(3)} `,
};

const tabButtonStyles = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: (theme) => theme.palette.primary.main,
  my: (theme) => theme.spacing(1),
  py: (theme) => theme.spacing(1),
  px: (theme) => theme.spacing(1.5),
  fontSize: "1.1rem",
  height: "100%",
  border: "1px solid",
  borderColor: (theme) => theme.palette.common.lightGray,
  borderRadius: (theme) => theme.spacing(1),
};

const Sidebar = ({
  counties,
  series,
  lookup,
  onLookupChange,
  manageUsers,
  onChangeManageUsers,
}) => {
  const theme = useTheme();
  const { loggedIn } = useContext(LoggedInContext);
  const smallerScreen = useMediaQuery("(max-height: 840px)");

  return (
    <Box sx={menuBoxStyles}>
      <Box width={1} display="flex" flexDirection="column">
        {/* Justice Bridge Logo */}
        <img
          src={justiceBridgeLogo}
          alt="Justice Bridge logo"
          width={smallerScreen ? "140px":"200px"}
          style={{ marginBottom: "20px", alignSelf: "center" }}
        />

        {/* Select fields */}
        <LookupSelectForms
          counties={counties}
          series={series}
          lookup={lookup}
          onLookupChange={onLookupChange}
          disabled={manageUsers}
        />

        {/* Manage users button */}
        {loggedIn && (
          <>
            <Divider sx={{ my: (theme) => theme.spacing(1) }} />
            <Box
              fullWidth
              sx={tabButtonStyles}
              // onClick={() =>
              //   loggedIn ? Logout() : setShowLoginForm(!showLoginForm)
              // }
            >
              <Typography variant="h6" color={theme.palette.common.white}>
                Manage users
              </Typography>

              <Switch
                checked={manageUsers}
                onChange={(e) => onChangeManageUsers(e.target.checked)}
                color="secondary"
              />
            </Box>
          </>
        )}

        {/* Need help link */}
        {/* <a
          href="#help"
          style={{
            textDecoration: "none",
            color: theme.palette.common.link,
            fontSize: "1.2rem",
          }}
        >
          Need help?
        </a> */}
      </Box>

      {/* AAC Logo */}
      <img src={aacLogo} alt="AAC logo" width="280px" />
    </Box>
  );
};

export default Sidebar;
