// MUI
import {
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  useTheme,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const textFieldStyles = {
  width: "100%",
  backgroundColor: (theme) => theme.palette.common.white,
  borderRadius: (theme) => theme.spacing(1),

  input: {
    fontSize: "15px",
    height: (theme) => theme.spacing(2),
  },
  [`& fieldset`]: {
    borderRadius: (theme) => theme.spacing(1),
    border: "1px solid ",
    borderColor: (theme) => theme.palette.common.lightGray,
  },
};


const NewPasswordForm = ({
  handleChange,
  showPassword,
  handleClickShowPassword,
}) => {
  const theme = useTheme();

  return (
    <>
      <Typography variant="body1" sx={{minHeight: "20px"}}>Enter your new password</Typography>
      <TextField
        id="user-password-new"
        variant="outlined"
        type={showPassword ? "text" : "password"}
        onChange={handleChange("newPassword")}
        placeholder="New Password"
        sx={{ ...textFieldStyles, mb: (theme) => theme.spacing(2) }}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="start"
                style={{ color: theme.palette.primary.main }}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

export default NewPasswordForm;
