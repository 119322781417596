import React from "react";

// Mui
import { Box, FormControl, Select, MenuItem, Typography } from "@mui/material";

const selectFieldStyles = {
  marginBottom: 1.5,
  height: "50px",
  borderRadius: (theme) => theme.spacing(1),
  backgroundColor: (theme) => theme.palette.common.white,

  [`& fieldset`]: {
    border: "1px solid ",
    borderColor: (theme) => theme.palette.common.lightGray,
  },
};
const fieldLabelStyles = {
  mb: (theme) => theme.spacing(0.7),
  fontSize: "1.2rem",
};

const Menu = ({ counties, series, lookup, onLookupChange, disabled }) => {
  return (
    <Box display="flex" flexDirection="column" width={1}>
      {/* Series lookup */}
      <FormControl fullWidth>
        {/* Label */}
        <Typography sx={fieldLabelStyles}>Series</Typography>
        <Select
          labelId="series-lookup"
          id="series-lookup-select"
          value={lookup.series}
          sx={selectFieldStyles}
          onChange={(e) =>
            onLookupChange({ ...lookup, series: e.target.value })
          }
          disabled={disabled}
        >
          <MenuItem key="All" value="All">
            All
          </MenuItem>
          {series &&
            series.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      {/* Country lookup */}
      <FormControl fullWidth>
        {/* Label */}
        <Typography sx={fieldLabelStyles}>Lookup via County</Typography>
        <Select
          labelId="county-lookup"
          id="contact-type-select"
          value={lookup.county}
          sx={selectFieldStyles}
          onChange={(e) =>
            onLookupChange({ ...lookup, county: e.target.value })
          }
          disabled={disabled}
        >
          <MenuItem key="All" value="All">
            All
          </MenuItem>
          {counties &&
            counties.map((option) => (
              <MenuItem key={option._id} value={option._Name}>
                {option._Name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default Menu;
