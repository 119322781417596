import React from "react";
import logo from "../../res/icons/Keystone_LOGO_V2.wdpic.png";

const Logo = ({ ...props }) => {
  return (
    <img
      src={logo}
      alt="logo"
      {...props}
    />
  );
};

export default Logo;
