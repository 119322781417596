import React, { useContext, useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Views
import AppContent from "./AppContent";
// import Panel from 'Views/Pages/Panel'

import {
  gvUserConnected,
  langMessages,
  setGvUserConnected,
  setLangData,
  axiosSiteDataConfig,
} from "./variables";

import {
  UserConnectedContext,
  LoggedInContext,
  UtilitiesContext,
} from "./contexts";

// Libs
import { SnackbarProvider, useSnackbar } from "notistack";
import axios from "axios";

//
// ────────────────────────────────────────────────── I ──────────
//   :::::: T H E M E : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────
//

const getDesignTokes = (mode) => ({
  palette: {
    mode,
    common: {
      black: "rgba(29, 29, 41, 1)",
      white: "#fff",
      lightGray: "#e0e0e0",
      link: "#6594E2",
    },
    background: {
      paper: mode === "light" ? "#fff" : "#1B2127",
      default: mode === "light" ? "#F8F9FF" : "#212121",
    },

    primary: {
      light: "#29669C",
      main: "#64B5F6",
      dark: "#4caaf5",
      contrastText: "#fff",
    },
    secondary: {
      light: "#004AFF",
      main: "#EFF6FF",
      dark: "#2F73AE",
      contrastText: "#fff",
    },
    error: {
      light: "rgba(252, 127, 114, 1)",
      main: "rgba(255, 31, 1, 1)",
      dark: "rgba(225, 27, 0, 1)",
      contrastText: "#fff",
    },
    text: {
      primary: mode === "light" ? "rgba(29, 29, 41, 1)" : "#fff",
      secondary:
        mode === "light" ? "rgba(29, 29, 41, 0.6)" : "rgba(255, 255, 255, 0.6)",
      disabled:
        mode === "light" ? "rgba(29, 29, 41, 0.4)" : "rgba(255, 255, 255, 0.4)",
      hint:
        mode === "light" ? "rgba(29, 29, 41, 0.4)" : "rgba(255, 255, 255, 0.4)",
      muted: "rgba(29, 29, 41, 0.45)",
    },
  },

  typography: {
    allVariants: {
      color: "rgba(56, 56, 58, 1)",
    },
    fontFamily: '"Gilroy-SemiBold", sans-serif',
    // fontSize: 20,
    h1: {
      fontFamily: '"Gilroy-SemiBold"',
      // fontSize: 25
    },
    h2: {
      fontFamily: '"Gilroy-SemiBold"',
      // fontSize: 18
    },
    h3: {
      fontFamily: '"Gilroy-SemiBold"',
      // "fontSize": 17
    },
    h4: {
      fontFamily: '"Gilroy-SemiBold"',
      lineHeight: "1.8",
      // "fontSize": 16
    },
    h5: {
      fontFamily: '"Gilroy-SemiBold"',
      // "fontSize": 15
    },
    h6: {
      fontFamily: '"Gilroy-SemiBold"',
      // "fontSize": 14
    },

    subtitle1: {
      fontFamily: '"Gilroy-SemiBold"',
      // "fontSize": 14
    },
    subtitle2: {
      fontFamily: '"Gilroy-SemiBold"',
      // "fontSize": 13
    },

    body1: {
      // "fontSize": 13
    },
    body2: {
      // "fontSize": 11
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        },
      },
    },
  },
  spacing: 10,
  shadows: [
    "none",
    "0px 2px 1px -1px rgba(41,102,156,0.3),0px 1px 1px 0px rgba(41,102,156,0.2),0px 1px 3px 0px rgba(41,102,156,0.12)",
    "0px 3px 1px -2px rgba(41,102,156,0.3),0px 2px 2px 0px rgba(41,102,156,0.2),0px 1px 5px 0px rgba(41,102,156,0.12)",
    "0px 3px 3px -2px rgba(41,102,156,0.3),0px 3px 4px 0px rgba(41,102,156,0.2),0px 1px 8px 0px rgba(41,102,156,0.12)",
    "0px 2px 4px -1px rgba(41,102,156,0.3),0px 4px 5px 0px rgba(41,102,156,0.2),0px 1px 10px 0px rgba(41,102,156,0.12)",
    "0px 3px 5px -1px rgba(41,102,156,0.3),0px 5px 8px 0px rgba(41,102,156,0.2),0px 1px 14px 0px rgba(41,102,156,0.12)",
    "0px 3px 5px -1px rgba(41,102,156,0.3),0px 6px 10px 0px rgba(41,102,156,0.2),0px 1px 18px 0px rgba(41,102,156,0.12)",
    "0px 4px 5px -2px rgba(41,102,156,0.3),0px 7px 10px 1px rgba(41,102,156,0.2),0px 2px 16px 1px rgba(41,102,156,0.12)",
    "0px 5px 5px -3px rgba(41,102,156,0.3),0px 8px 10px 1px rgba(41,102,156,0.2),0px 3px 14px 2px rgba(41,102,156,0.12)",
    "0px 5px 6px -3px rgba(41,102,156,0.3),0px 9px 12px 1px rgba(41,102,156,0.2),0px 3px 16px 2px rgba(41,102,156,0.12)",
    "0px 6px 6px -3px rgba(41,102,156,0.3),0px 10px 14px 1px rgba(41,102,156,0.2),0px 4px 18px 3px rgba(41,102,156,0.12)",
    "0px 6px 7px -4px rgba(41,102,156,0.3),0px 11px 15px 1px rgba(41,102,156,0.2),0px 4px 20px 3px rgba(41,102,156,0.12)",
    "0px 7px 8px -4px rgba(41,102,156,0.3),0px 12px 17px 2px rgba(41,102,156,0.2),0px 5px 22px 4px rgba(41,102,156,0.12)",
    "0px 7px 8px -4px rgba(41,102,156,0.3),0px 13px 19px 2px rgba(41,102,156,0.2),0px 5px 24px 4px rgba(41,102,156,0.12)",
    "0px 7px 9px -4px rgba(41,102,156,0.3),0px 14px 21px 2px rgba(41,102,156,0.2),0px 5px 26px 4px rgba(41,102,156,0.12)",
    "0px 8px 9px -5px rgba(41,102,156,0.3),0px 15px 22px 2px rgba(41,102,156,0.2),0px 6px 28px 5px rgba(41,102,156,0.12)",
    "0px 8px 10px -5px rgba(41,102,156,0.3),0px 16px 24px 2px rgba(41,102,156,0.2),0px 6px 30px 5px rgba(41,102,156,0.12)",
    "0px 8px 11px -5px rgba(41,102,156,0.3),0px 17px 26px 2px rgba(41,102,156,0.2),0px 6px 32px 5px rgba(41,102,156,0.12)",
    "0px 9px 11px -5px rgba(41,102,156,0.3),0px 18px 28px 2px rgba(41,102,156,0.2),0px 7px 34px 6px rgba(41,102,156,0.12)",
    "0px 9px 12px -6px rgba(41,102,156,0.3),0px 19px 29px 2px rgba(41,102,156,0.2),0px 7px 36px 6px rgba(41,102,156,0.12)",
    "0px 10px 13px -6px rgba(41,102,156,0.3),0px 20px 31px 3px rgba(41,102,156,0.2),0px 8px 38px 7px rgba(41,102,156,0.12)",
    "0px 10px 13px -6px rgba(41,102,156,0.3),0px 21px 33px 3px rgba(41,102,156,0.2),0px 8px 40px 7px rgba(41,102,156,0.12)",
    "0px 10px 14px -6px rgba(41,102,156,0.3),0px 22px 35px 3px rgba(41,102,156,0.2),0px 8px 42px 7px rgba(41,102,156,0.12)",
    "0px 11px 14px -7px rgba(41,102,156,0.3),0px 23px 36px 3px rgba(41,102,156,0.2),0px 9px 44px 8px rgba(41,102,156,0.12)",
    "0px 11px 15px -7px rgba(41,102,156,0.3),0px 24px 38px 3px rgba(41,102,156,0.2),0px 9px 46px 8px rgba(41,102,156,0.12)",
  ],
});

export const theme = createTheme(getDesignTokes("light"));

// ────────────────────────────────────────────────────────────────────────────────

//
// ──────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: G L O B A L   V A R I A B L E S : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────────────────
//

//
// ───────────────────────────────────────────────────── END GLOBAL VARIABLES ─────

function AppConfig() {
  // Contexts
  const { enqueueSnackbar } = useSnackbar();
  const { userConnected } = useContext(UserConnectedContext);

  // States
  const [loggedIn, setLoggedIn] = useState(false);

  // ─── FUNCTIONS ──────────────────────────────────────────────────────────────────
  // Logs out a user
  const Logout = () => {
    setGvUserConnected({
      ...gvUserConnected,
      name: "",
      email: "",
      county: "",
      phone: "",
      token: "",
      type: "admin",
    });
    localStorage.removeItem("user");
    setLoggedIn(false);
  };

  useEffect(() => {
    let theUser;
    try {
      theUser = JSON.parse(localStorage.getItem("user"));
      if (theUser.email.trim() === "") {
        //#TO-REVERTBACK
        Logout();
        // setPage('default')
        return;
      }
    } catch (_) {
      //#TO-REVERTBACK
      Logout();
      // setPage('default')
      return;
    }
    setGvUserConnected(theUser);
    setLoggedIn(true);
  }, []);

  // Returns an error after running an api
  const APIError = (error, toTranslate = false) => {
    if (error.response) {
      enqueueSnackbar(
        toTranslate
          ? langMessages.messages?.[userConnected.lang]?.[
              error.response.data.result
            ] || ""
          : error.response.data.result,
        { variant: "error" }
      );
      if (error.response.status === 401) Logout();
    } else {
      enqueueSnackbar("You're offline. Please check your internet and retry.", {
        variant: "error",
      });
    }
  };

  return (
    <LoggedInContext.Provider value={{ loggedIn, setLoggedIn }}>
      <UtilitiesContext.Provider value={{ Logout, APIError }}>
        {/* Main content */}
        <AppContent />
      </UtilitiesContext.Provider>
    </LoggedInContext.Provider>
  );
}

function App() {
  const [userConnected, setUserConnected] = useState(gvUserConnected);
  useEffect(() => {
    let theUser;
    try {
      theUser = JSON.parse(localStorage.getItem("user"));
      if (!theUser) {
        theUser = gvUserConnected;
      }
    } catch (_) {
      setUserConnected(gvUserConnected);
      return;
    }

    setUserConnected(theUser);
    setGvUserConnected(theUser);
  }, []);

  // Inits
  useEffect(() => {
    //Langs
    axios.get(`/lang.json`, axiosSiteDataConfig).then((response) => {
      setLangData(response.data);
    });
  }, []);

  return (
    <UserConnectedContext.Provider value={{ userConnected, setUserConnected }}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          autoHideDuration={3000}
          maxSnack={1}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <AppConfig />
        </SnackbarProvider>
      </ThemeProvider>
    </UserConnectedContext.Provider>
  );
}

export default App;
