import axios from 'axios'

export const axiosSiteData = axios.create()
axiosSiteData.defaults.baseURL = process.env.REACT_APP_BASE_URL

export const projectName = 'Justice Bridge Directory' 

export const versionCode = "2.1.0"

export let gvUserConnected = {
    name: '',
    email: '',
    county: '',
    phone: '',
    token: '',
    type: 'admin',
    companyID: '',
    theme: 'dark',
    lang: 'en'
}
//

export let axiosSiteDataConfig = { headers: { 'user-token': gvUserConnected.token } }

export function setGvUserConnected(userConnected) {
    gvUserConnected = userConnected
    localStorage.setItem('user', JSON.stringify(gvUserConnected))
    axiosSiteDataConfig.headers['user-token'] = gvUserConnected.token
}

export function getFileExtension(filename) {
    return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase();
}

// Language
export let langMessages
export function setLangData(data){
    langMessages = data
}


export function SetLangMessages(messages, afterUploading, onError) {
    // We upload Lang data
    axiosSiteData.post(`/files/lang.json`, messages, axiosSiteDataConfig)
    .then((_) => {

        langMessages = messages
        afterUploading()
    })
    .catch(_ => onError())
}

export const UserTypes = {
    SuperAdmin: "super-admin",
    Admin: "admin",
    User: "user",
}

export const SeriesTypes = {
    StatePrisons: "State Prisons",
    JailDetention: "Jail / Detention",
    Sheriff : "Sheriff",
    CountyJudge  : "County Judge",
    PublicDefender: "Public Defender",
    ProsecutorsOffice: "Prosecutors Office",
    CircuitCourt: "Circuit Court",
    DistrictCourt: "District Court",
    ParoleProbation: "Parole/Probation",
    ArkansasSheriffsAssociation: "Arkansas Sheriffs Association",
}





