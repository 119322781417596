import React from "react";

// MUI
import {Typography, TextField } from "@mui/material";


const textFieldStyles = {
  width: "100%",
  backgroundColor: (theme) => theme.palette.common.white,
  borderRadius: (theme) => theme.spacing(1),

  input: {
    fontSize: "15px",
    height: (theme) => theme.spacing(2),
  },
  [`& fieldset`]: {
    borderRadius: (theme) => theme.spacing(1),
    border: "1px solid ",
    borderColor: (theme) => theme.palette.common.lightGray,
  },
};

const EnterCodeForm = ({ handleChange}) => {

  return (
    <>
      <Typography variant="body1">
        Enter the reset code you received in your email inbox
      </Typography>
      <TextField
        id="user-reset-password-code"
        variant="outlined"
        sx={{ ...textFieldStyles, mb: (theme) => theme.spacing(2) }}
        onChange={handleChange("resetCode")}
        placeholder="Reset code"
        fullWidth
      />
    </>
  );
};

export default EnterCodeForm;
